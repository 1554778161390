/*!
 * Glyphicons - Bootstrap v3
 */
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.1/fonts/glyphicons-halflings-regular.eot);
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.1/fonts/glyphicons-halflings-regular.eot?#iefix) format('embedded-opentype'), url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.1/fonts/glyphicons-halflings-regular.woff) format('woff'), url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.1/fonts/glyphicons-halflings-regular.ttf) format('truetype'), url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.1/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format('svg');
}
.glyphicon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  position: relative;
  top: 1px;
}
.glyphicon-adjust:before {
  content: "\e063";
}
.glyphicon-asterisk:before {
  content: "\2a";
}
.glyphicon-backward:before {
  content: "\e071";
}
.glyphicon-ban-circle:before {
  content: "\e090";
}
.glyphicon-barcode:before {
  content: "\e040";
}
.glyphicon-bell:before {
  content: "\e123";
}
.glyphicon-bold:before {
  content: "\e048";
}
.glyphicon-book:before {
  content: "\e043";
}
.glyphicon-bookmark:before {
  content: "\e044";
}
.glyphicon-briefcase:before {
  content: "\e139";
}
.glyphicon-bullhorn:before {
  content: "\e122";
}
.glyphicon-calendar:before {
  content: "\e109";
}
.glyphicon-camera:before {
  content: "\e046";
}
.glyphicon-certificate:before {
  content: "\e124";
}
.glyphicon-check:before {
  content: "\e067";
}
.glyphicon-cog:before {
  content: "\e019";
}
.glyphicon-comment:before {
  content: "\e111";
}
.glyphicon-compressed:before {
  content: "\e181";
}
.glyphicon-copyright-mark:before {
  content: "\e194";
}
.glyphicon-credit-card:before {
  content: "\e177";
}
.glyphicon-cutlery:before {
  content: "\e179";
}
.glyphicon-dashboard:before {
  content: "\e141";
}
.glyphicon-earphone:before {
  content: "\e182";
}
.glyphicon-edit:before {
  content: "\e065";
}
.glyphicon-eject:before {
  content: "\e078";
}
.glyphicon-envelope:before {
  content: "\2709";
}
.glyphicon-eur:before {
  content: "\20ac";
}
.glyphicon-euro:before {
  content: "\20ac";
}
.glyphicon-exclamation-sign:before {
  content: "\e101";
}
.glyphicon-expand:before {
  content: "\e158";
}
.glyphicon-export:before {
  content: "\e170";
}
.glyphicon-facetime-video:before {
  content: "\e059";
}
.glyphicon-file:before {
  content: "\e022";
}
.glyphicon-film:before {
  content: "\e009";
}
.glyphicon-filter:before {
  content: "\e138";
}
.glyphicon-fire:before {
  content: "\e104";
}
.glyphicon-flag:before {
  content: "\e034";
}
.glyphicon-flash:before {
  content: "\e162";
}
.glyphicon-font:before {
  content: "\e047";
}
.glyphicon-forward:before {
  content: "\e075";
}
.glyphicon-fullscreen:before {
  content: "\e140";
}
.glyphicon-gbp:before {
  content: "\e149";
}
.glyphicon-gift:before {
  content: "\e102";
}
.glyphicon-glass:before {
  content: "\e001";
}
.glyphicon-globe:before {
  content: "\e135";
}
.glyphicon-hd-video:before {
  content: "\e187";
}
.glyphicon-hdd:before {
  content: "\e121";
}
.glyphicon-header:before {
  content: "\e180";
}
.glyphicon-headphones:before {
  content: "\e035";
}
.glyphicon-home:before {
  content: "\e021";
}
.glyphicon-import:before {
  content: "\e169";
}
.glyphicon-inbox:before {
  content: "\e028";
}
.glyphicon-info-sign:before {
  content: "\e086";
}
.glyphicon-italic:before {
  content: "\e049";
}
.glyphicon-leaf:before {
  content: "\e103";
}
.glyphicon-link:before {
  content: "\e144";
}
.glyphicon-lock:before {
  content: "\e033";
}
.glyphicon-magnet:before {
  content: "\e112";
}
.glyphicon-map-marker:before {
  content: "\e062";
}
.glyphicon-move:before {
  content: "\e068";
}
.glyphicon-music:before {
  content: "\e002";
}
.glyphicon-new-window:before {
  content: "\e164";
}
.glyphicon-off:before {
  content: "\e017";
}
.glyphicon-open:before {
  content: "\e167";
}
.glyphicon-paperclip:before {
  content: "\e142";
}
.glyphicon-pause:before {
  content: "\e073";
}
.glyphicon-pencil:before {
  content: "\270f";
}
.glyphicon-picture:before {
  content: "\e060";
}
.glyphicon-plane:before {
  content: "\e108";
}
.glyphicon-print:before {
  content: "\e045";
}
.glyphicon-pushpin:before {
  content: "\e146";
}
.glyphicon-qrcode:before {
  content: "\e039";
}
.glyphicon-question-sign:before {
  content: "\e085";
}
.glyphicon-random:before {
  content: "\e110";
}
.glyphicon-record:before {
  content: "\e165";
}
.glyphicon-refresh:before {
  content: "\e031";
}
.glyphicon-registration-mark:before {
  content: "\e195";
}
.glyphicon-repeat:before {
  content: "\e030";
}
.glyphicon-retweet:before {
  content: "\e115";
}
.glyphicon-road:before {
  content: "\e024";
}
.glyphicon-save:before {
  content: "\e166";
}
.glyphicon-saved:before {
  content: "\e168";
}
.glyphicon-screenshot:before {
  content: "\e087";
}
.glyphicon-sd-video:before {
  content: "\e186";
}
.glyphicon-search:before {
  content: "\e003";
}
.glyphicon-send:before {
  content: "\e171";
}
.glyphicon-shopping-cart:before {
  content: "\e116";
}
.glyphicon-signal:before {
  content: "\e018";
}
.glyphicon-stats:before {
  content: "\e185";
}
.glyphicon-stop:before {
  content: "\e074";
}
.glyphicon-subtitles:before {
  content: "\e188";
}
.glyphicon-tag:before {
  content: "\e041";
}
.glyphicon-tags:before {
  content: "\e042";
}
.glyphicon-tasks:before {
  content: "\e137";
}
.glyphicon-time:before {
  content: "\e023";
}
.glyphicon-tint:before {
  content: "\e064";
}
.glyphicon-tower:before {
  content: "\e184";
}
.glyphicon-transfer:before {
  content: "\e178";
}
.glyphicon-trash:before {
  content: "\e020";
}
.glyphicon-unchecked:before {
  content: "\e157";
}
.glyphicon-upload:before {
  content: "\e027";
}
.glyphicon-usd:before {
  content: "\e148";
}
.glyphicon-user:before {
  content: "\e008";
}
.glyphicon-warning-sign:before {
  content: "\e107";
}
.glyphicon-wrench:before {
  content: "\e136";
}
.glyphicon-align-left:before {
  content: "\e052";
}
.glyphicon-align-center:before {
  content: "\e053";
}
.glyphicon-align-right:before {
  content: "\e054";
}
.glyphicon-align-justify:before {
  content: "\e055";
}
.glyphicon-arrow-left:before {
  content: "\e091";
}
.glyphicon-arrow-right:before {
  content: "\e092";
}
.glyphicon-arrow-up:before {
  content: "\e093";
}
.glyphicon-arrow-down:before {
  content: "\e094";
}
.glyphicon-chevron-down:before {
  content: "\e114";
}
.glyphicon-chevron-left:before {
  content: "\e079";
}
.glyphicon-chevron-right:before {
  content: "\e080";
}
.glyphicon-chevron-up:before {
  content: "\e113";
}
.glyphicon-circle-arrow-down:before {
  content: "\e134";
}
.glyphicon-circle-arrow-left:before {
  content: "\e132";
}
.glyphicon-circle-arrow-right:before {
  content: "\e131";
}
.glyphicon-circle-arrow-up:before {
  content: "\e133";
}
.glyphicon-cloud:before {
  content: "\2601";
}
.glyphicon-cloud-download:before {
  content: "\e197";
}
.glyphicon-cloud-upload:before {
  content: "\e198";
}
.glyphicon-collapse-down:before {
  content: "\e159";
}
.glyphicon-collapse-up:before {
  content: "\e160";
}
.glyphicon-download:before {
  content: "\e026";
}
.glyphicon-download-alt:before {
  content: "\e025";
}
.glyphicon-eye-close:before {
  content: "\e106";
}
.glyphicon-eye-open:before {
  content: "\e105";
}
.glyphicon-fast-backward:before {
  content: "\e070";
}
.glyphicon-fast-forward:before {
  content: "\e076";
}
.glyphicon-floppy-disk:before {
  content: "\e172";
}
.glyphicon-floppy-open:before {
  content: "\e176";
}
.glyphicon-floppy-remove:before {
  content: "\e174";
}
.glyphicon-floppy-save:before {
  content: "\e175";
}
.glyphicon-floppy-saved:before {
  content: "\e173";
}
.glyphicon-folder-close:before {
  content: "\e117";
}
.glyphicon-folder-open:before {
  content: "\e118";
}
.glyphicon-hand-down:before {
  content: "\e130";
}
.glyphicon-hand-left:before {
  content: "\e128";
}
.glyphicon-hand-right:before {
  content: "\e127";
}
.glyphicon-hand-up:before {
  content: "\e129";
}
.glyphicon-heart:before {
  content: "\e005";
}
.glyphicon-heart-empty:before {
  content: "\e143";
}
.glyphicon-indent-left:before {
  content: "\e057";
}
.glyphicon-indent-right:before {
  content: "\e058";
}
.glyphicon-list:before {
  content: "\e056";
}
.glyphicon-list-alt:before {
  content: "\e032";
}
.glyphicon-log-in:before {
  content: "\e161";
}
.glyphicon-log-out:before {
  content: "\e163";
}
.glyphicon-minus:before {
  content: "\2212";
}
.glyphicon-minus-sign:before {
  content: "\e082";
}
.glyphicon-ok:before {
  content: "\e013";
}
.glyphicon-ok-circle:before {
  content: "\e089";
}
.glyphicon-ok-sign:before {
  content: "\e084";
}
.glyphicon-phone:before {
  content: "\e145";
}
.glyphicon-phone-alt:before {
  content: "\e183";
}
.glyphicon-play:before {
  content: "\e072";
}
.glyphicon-play-circle:before {
  content: "\e029";
}
.glyphicon-plus:before {
  content: "\2b";
}
.glyphicon-plus-sign:before {
  content: "\e081";
}
.glyphicon-remove:before {
  content: "\e014";
}
.glyphicon-remove-circle:before {
  content: "\e088";
}
.glyphicon-remove-sign:before {
  content: "\e083";
}
.glyphicon-resize-full:before {
  content: "\e096";
}
.glyphicon-resize-horizontal:before {
  content: "\e120";
}
.glyphicon-resize-small:before {
  content: "\e097";
}
.glyphicon-resize-vertical:before {
  content: "\e119";
}
.glyphicon-share:before {
  content: "\e066";
}
.glyphicon-share-alt:before {
  content: "\e095";
}
.glyphicon-sort:before {
  content: "\e150";
}
.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}
.glyphicon-sort-by-order:before {
  content: "\e153";
}
.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}
.glyphicon-sort-by-attributes:before {
  content: "\e155";
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}
.glyphicon-sound-5-1:before {
  content: "\e191";
}
.glyphicon-sound-6-1:before {
  content: "\e192";
}
.glyphicon-sound-7-1:before {
  content: "\e193";
}
.glyphicon-sound-dolby:before {
  content: "\e190";
}
.glyphicon-sound-stereo:before {
  content: "\e189";
}
.glyphicon-star:before {
  content: "\e006";
}
.glyphicon-star-empty:before {
  content: "\e007";
}
.glyphicon-step-backward:before {
  content: "\e069";
}
.glyphicon-step-forward:before {
  content: "\e077";
}
.glyphicon-text-height:before {
  content: "\e050";
}
.glyphicon-text-width:before {
  content: "\e051";
}
.glyphicon-th:before {
  content: "\e011";
}
.glyphicon-th-large:before {
  content: "\e010";
}
.glyphicon-th-list:before {
  content: "\e012";
}
.glyphicon-thumbs-down:before {
  content: "\e126";
}
.glyphicon-thumbs-up:before {
  content: "\e125";
}
.glyphicon-tree-conifer:before {
  content: "\e199";
}
.glyphicon-tree-deciduous:before {
  content: "\e200";
}
.glyphicon-volume-off:before {
  content: "\e036";
}
.glyphicon-volume-down:before {
  content: "\e037";
}
.glyphicon-volume-up:before {
  content: "\e038";
}
.glyphicon-zoom-in:before {
  content: "\e015";
}
.glyphicon-zoom-out:before {
  content: "\e016";
}
/*@/global/core/_body.less*/
body {
  overflow: hidden;
}
/*@/global/core/_main.less*/
#mainPage {
  height: 100vh;
}
#sidebitch,
#mainbitch {
  padding-top: 51px;
}
#mainbitch {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  height: 100%;
}
/*!
 * Global Cytube-specific styles
 */
/*@/global/cytube/_alert.less*/
.alert {
  border-radius: 0;
  border-width: 2px;
}
.alert .col {
  padding-top: 1px;
}
.alert.alert-primary .alert-icon-container,
.alert.alert-secondary .alert-icon-container,
.alert.alert-info .alert-icon-container,
.alert.alert-warning .alert-icon-container,
.alert.alert-danger .alert-icon-container {
  width: 28px;
}
/*@/global/cytube/_core.less*/
body.container-fluid,
section#mainpage,
html {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
#mainbitch {
  overflow-y: scroll;
}
footer#footer {
  height: 40px;
}
/*@/global/cytube/_card.less*/
.card.account-channels {
  max-width: 750px;
}
.card.account-edit {
  max-width: 700px;
}
.card.password-reset {
  max-width: 500px;
}
.card.profile {
  max-width: 750px;
}
.card.login {
  max-width: 400px;
}
.card.register {
  max-width: 500px;
}
/*@/global/cytube/_tablesorter.less*/
table.tablesorter {
  width: 100%;
  text-align: left;
}
table.tablesorter thead tr .header {
  background-image: url(../img/bg.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}
table.tablesorter thead tr .headerSortUp {
  background-image: url(../img/asc.gif);
}
table.tablesorter thead tr .headerSortDown {
  background-image: url(../img/desc.gif);
}
table.tablesorter tbody td {
  vertical-align: top;
}
.tablesorter .filtered {
  display: none;
}
.tablesorter-filter-row td {
  line-height: normal;
  text-align: center;
  /* center the input */
  -webkit-transition: line-height 0.1s ease;
  transition: line-height 0.1s ease;
}
.tablesorter-filter-row.hideme td {
  margin: 0;
  line-height: 0;
  cursor: pointer;
}
.tablesorter-filter-row.hideme * {
  height: 1px;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
/* filters */
.tablesorter-filter {
  width: 100%;
  height: inherit;
  /*margin: 4px;*/
  /*padding: 4px;*/
  border-style: solid;
}
/*@/global/cytube/_utilities.less*/
/*@/global/cytube/utilities/helper.less*/
.pointer {
  cursor: pointer;
}
.invis {
  display: none;
  visibility: hidden;
}
.fadeOut5 {
  opacity: 0;
  -webkit-transition: opacity 5s linear;
  transition: opacity 5s linear;
}
.ImageContainer {
  width: 100%;
  float: left;
}
/*@/global/cytube/_nav.less*/
#pageTop {
  position: absolute;
  width: 100%;
  z-index: 5;
}
nav.navbar {
  padding: 7px 14px;
}
nav.navbar .navbar-brand {
  height: 24px;
  padding: 0 12px 0 0;
  font-size: 14px;
}
nav.navbar .navbar-brand a {
  font-size: 14px;
}
nav.navbar .navbar-nav .nav-link {
  padding: 7px 11px;
}
nav.navbar .navbar-nav .nav-link .ch-icon {
  width: 23px;
  height: 23px;
  -webkit-transition: fill 0.35s ease-in-out;
  transition: fill 0.35s ease-in-out;
}
nav.navbar .navbar-nav .nav-link .ch-icon:hover,
nav.navbar .navbar-nav .nav-link .ch-icon:focus {
  fill: #78cbe4;
}
nav.navbar .navbar-nav .nav-item.divider {
  font-size: 18px;
  font-weight: 500;
  padding-top: 4px;
}
#logout {
  -webkit-transition: color 0.35s ease-in-out;
  transition: color 0.35s ease-in-out;
}
#nav-collapsible.show .nav-item.divider,
#nav-collapsible.collapsing .nav-item.divider {
  display: none;
}
#nav-collapsible.show .nav-item.dropdown > .dropdown-menu.show .dropdown-item,
#nav-collapsible.collapsing .nav-item.dropdown > .dropdown-menu.show .dropdown-item,
#nav-collapsible.show .nav-item.dropdown > .dropdown-menu.collapsing .dropdown-item,
#nav-collapsible.collapsing .nav-item.dropdown > .dropdown-menu.collapsing .dropdown-item {
  padding: 4px 16px;
}
#nav-collapsible .nav-item.dropdown:active,
#nav-collapsible .nav-item.dropdown .nav-link:active,
#nav-collapsible .nav-item.dropdown:focus,
#nav-collapsible .nav-item.dropdown .nav-link:focus {
  outline-width: 2px;
}
#nav-collapsible:not(.show) #navbarDropdown ~ .dropdown-menu {
  left: -18px;
}
/*@/global/cytube/_chan.less*/
#chanjs-allow-prompt {
  text-align: center;
}
#chanjs-allow-prompt-buttons {
  margin-top: 10px;
}
#chanjs-allow-prompt-buttons button:first-child {
  margin-right: 5px;
}
.btn.account-channel-delete {
  width: 24px;
  height: 24px;
}
.btn.account-channel-delete .btn-icon {
  margin-top: -3px;
  width: 12px;
  height: 12px;
}
#chanlog_contents {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 10px;
}
/*@/global/cytube/_chat.less*/
.chat-name {
  font-weight: bold;
}
#chatline {
  margin-top: 0;
  border-radius: 0;
}
#chatheader > p {
  margin: 0;
}
#chatheader .label {
  height: 100%;
  margin-left: 2px;
}
#chatwrap {
  margin-bottom: 10px;
}
#chatDragHandle {
  width: 100%;
  cursor: n-resize;
  height: 2px;
}
.drink {
  border: 2px solid;
  margin: 10px 10px;
  padding: 10px 0;
}
#drinkcount {
  text-align: center;
  font-weight: bold;
}
.emote-preview {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}
.emote-preview-hax {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.emote-preview-container {
  width: 100px;
  height: 100px;
  float: left;
  text-align: center;
  white-space: nowrap;
  margin: 5px;
}
.emotelist-table {
  margin: auto;
}
.emotelist-paginator-container {
  text-align: center;
}
.channel-emote {
  max-width: 200px;
  max-height: 200px;
}
.messagebox > p {
  margin-top: 20px;
}
#messagebuffer {
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
  height: 229px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 0;
  min-height: 100px;
}
#newmessages-indicator {
  margin-top: -30px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}
#newmessages-indicator .glyphicon {
  margin-left: 10px;
  margin-right: 10px;
}
.server-msg-disconnect,
.server-msg-reconnect {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.messagesDiv,
.messagesLabel,
.messagesTextarea {
  float: left;
  width: 100%;
}
.messagesDiv {
  margin-top: 12px;
}
.messagesLabel {
  text-align: left;
}
/*@/global/cytube/_login.less*/
#guestlogin > input,
#guestlogin > .input-group-addon {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#guestlogin .input-group-addon {
  min-width: 120px;
}
#loginform > .form-group {
  margin-right: 5px;
}
input#logout[type="submit"] {
  background: none;
  border: none;
  padding: 0;
}
input#logout[type="submit"]:hover {
  text-decoration: underline;
}
/*@/global/cytube/_motd.less*/
#togglemotd .glyphicon {
  font-size: 10pt;
}
/*@/global/cytube/_options.less*/
#cs-motdtext,
#cs-csstext,
#cs-jstext {
  font-family: "Roboto Mono";
}
#cs-motdsubmit,
#cs-csssubmit,
#cs-jssubmit {
  margin-top: 10px;
}
#cs-chatfilters input[type='text'],
#cs-emotes input[type='text'],
#cs-chatfilters textarea,
#cs-emotes textarea {
  font-family: "Roboto Mono";
}
#cs-chatfilters-exporttext,
#cs-emotes-exporttext {
  margin-top: 5px;
}
#cs-chatfilters table .form-group {
  max-width: 25%;
}
#cs-chatfilters table .form-group > input {
  max-width: 100%;
}
#cs-emotes td:nth-child(3) {
  max-width: 300px;
}
#cs-chanlog-filter {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#cs-chanlog-text {
  max-height: 300px;
  overflow-y: scroll;
  font-size: 8pt;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#csstext,
#jstext {
  font-family: "Roboto Mono";
}
#optedit,
#permedit,
#filteredit,
#motdedit,
#cssedit,
#jsedit,
#banlist,
#loginhistory,
#channelranks,
#chanlog {
  display: none;
}
/*@/global/cytube/_playlist.less*/
#plmeta {
  border-radius: 4px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#pllength {
  float: right;
}
#playlistmanagerwrap {
  margin-top: 10px;
}
#userpl_list {
  list-style: none outside none;
  margin-left: 0;
  max-height: 500px;
  overflow-y: scroll;
}
#userpl_list > li:last-child {
  border-bottom-width: 1px;
}
/*@/global/cytube/_pm.less*/
#pmbar {
  position: fixed;
  bottom: 0;
  left: 242px;
  z-index: 10000;
  min-width: 100%;
  pointer-events: none;
}
.pm-input {
  margin: 0;
  width: 100%;
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
}
.pm-panel {
  margin-bottom: 0!important;
  border-radius: 0!important;
}
.pm-panel,
.pm-panel-placeholder {
  margin-left: 5px;
  margin-bottom: 20px;
  float: left;
  width: 250px;
  pointer-events: auto;
}
.pm-panel > .panel-heading {
  cursor: pointer;
  border-radius: 0!important;
}
.pm-panel > .panel-body {
  padding: 0;
}
.pm-panel > .panel-body > .pm-buffer {
  height: 200px;
  overflow-y: scroll;
}
.pm-panel > .panel-body > hr {
  margin: 0;
}
body.chatOnly .pm-panel,
body.chatOnly .pm-panel-placeholder {
  margin-left: 0;
  margin-right: 5px;
  float: right;
}
/*@/global/cytube/_poll.less*/
#pollwrap > div {
  margin-top: 10px;
}
#pollwrap .poll-card {
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.poll-menu {
  margin-top: 10px;
}
.poll-menu > .card {
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.poll-menu .custom-checkbox .custom-control-label::before {
  left: -24px;
  top: 1px;
}
.poll-menu .custom-checkbox .custom-control-label::after {
  left: -24px;
  top: 0;
}
.poll > .btn,
.poll > input {
  margin-bottom: 10px;
}
/*@/global/cytube/_profile.less*/
.profile.card .profile-box {
  z-index: 1;
}
.profile-box {
  min-width: 125px;
  max-width: 200px;
  max-height: 300px;
  overflow-y: hidden;
}
.profile-image {
  max-width: 80px;
  max-height: 80px;
}
/*@/global/cytube/_queue.less*/
#queue > li:last-child {
  border-bottom-width: 0;
  margin-bottom: 0;
}
#queuenext {
  border-radius: 0;
}
.queue {
  /* &_temp {
    background-image: url(/img/stripe-diagonal.png);
  } */
}
.queue_sortable li {
  cursor: row-resize;
}
.queue_entry {
  line-height: 22px;
  padding: 2px;
  font-size: 8pt;
  border: 1px solid #2a3439;
  border-top-width: 0;
}
.qe_btn {
  height: 20px;
  font-family: "Roboto Mono";
  padding: 0 5px 0 5px;
  margin: auto;
  overflow: hidden;
}
.qe_buttons,
.qe_title {
  float: left;
}
.qe_time {
  float: right;
  font-family: "Roboto Mono";
}
.qe_clear {
  clear: both;
}
#ce_queue_next {
  border-radius: 0;
}
li.ui-sortable-helper,
li.ui-sortable-placeholder + li.queue_entry {
  border-top-width: 1px;
}
.qfalert {
  margin-bottom: 10px;
  padding-left: 0!important;
  padding-right: 0!important;
}
#search_clear {
  margin-top: 10px;
}
#from-url,
#from-search {
  margin-top: 3px;
}
/*@/global/cytube/_social.less*/
#soundcloud-volume {
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
#soundcloud-volume-holder {
  position: absolute;
  top: 170px;
  width: 100%;
}
#soundcloud-volume-label {
  margin-left: 2px;
}
/*@/global/cytube/_svg.less*/
.svgButtonIcon {
  height: 100%;
  width: 100%;
}
.svgInvIcon {
  height: 50px;
}
/*@/global/cytube/_video.less*/
#leftcontrols .btn {
  margin-right: 5px;
}
#customembed_wrap {
  margin: 5px 0;
}
#customembed > .input-group {
  margin-top: 5px;
}
#customembed-content {
  font-family: "Roboto Mono";
}
.embed-responsive-chat {
  padding-bottom: 78.75%;
}
#library {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 5px;
}
#library_search {
  border-radius: 0;
}
body.hd #resize-video-larger,
body.hd #resize-video-smaller {
  display: none;
}
#resize-video-larger,
#resize-video-smaller {
  float: right;
}
.videolist {
  list-style: none outside none;
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow-y: auto;
}
.videolist > li:first-child {
  border-top-width: 1px;
}
#videowrap {
  margin-bottom: 10px;
}
#videowrap-header {
  margin: 0;
}
#videowrap .embed-responsive:full-screen {
  width: 100%;
}
/*!
 * Coolhole-specific styles
 */
/*@/global/coolhole/_core.less*/
.nameContainer,
.statusDiv,
.nameDiv,
.modalMessagesDiv {
  float: left;
}
.nameContainer {
  width: 100%;
}
.modalMessagesDiv {
  width: 80%;
}
.floatingDivBody,
.floatingDivTable,
.floatingDivHeader {
  float: left;
  width: 100%;
}
.floatingDivTable {
  overflow-x: hidden;
  overflow-y: scroll;
}
.floatingDivClose {
  float: right;
  cursor: pointer;
  -webkit-appearance: none;
}
.propTypeDiv,
.propDetailsDiv {
  float: left;
  width: 100%;
}
img.debug-console {
  max-height: 400px;
}
svg.ch-cp {
  height: 18px;
  width: 18px;
}
table td {
  word-wrap: break-word;
}
table.storeItemTable,
table.screenList,
table.overviewTable {
  width: 100%;
}
table.storeItemTable thead tr .header,
table.screenList thead tr .header,
table.overviewTable thead tr .header {
  background-image: url(../img/bg.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}
table.storeItemTable thead tr .headerSortUp,
table.screenList thead tr .headerSortUp,
table.overviewTable thead tr .headerSortUp {
  background-image: url(../img/asc.gif);
}
table.storeItemTable thead tr .headerSortDown,
table.screenList thead tr .headerSortDown,
table.overviewTable thead tr .headerSortDown {
  background-image: url(../img/desc.gif);
}
table.storeItemTable tbody td,
table.screenList tbody td,
table.overviewTable tbody td {
  vertical-align: center;
}
table.screenList tbody tr:hover {
  cursor: pointer;
}
table.overviewTable {
  position: relative;
  float: left;
}
.overviewTableWrapper {
  float: left;
  overflow-y: auto;
  position: relative;
  height: 250px;
}
.countTable {
  width: 100%;
  float: left;
}
.countValue {
  text-align: left;
}
.table-list {
  width: 100%;
}
/*@/global/coolhole/_action.less*/
.actionTableHeader,
.actionItemName {
  float: left;
  width: 100%;
}
.actionTableHeader {
  padding: 10px;
}
.actionItemName {
  text-align: center;
  white-space: normal;
}
.actionBtn,
.actionBtnContainer,
.actionBtnSelected {
  float: left;
  width: 100%;
}
.actionBtn,
.actionBtnSelected {
  text-align: center;
  white-space: normal;
}
.actionBtnContainer {
  pointer-events: auto;
}
.actionBtnCooldown {
  z-index: 99;
}
.actionBtnCooldown,
.actionBtnCooldownTime {
  position: absolute;
}
.actionBtnCooldownTime {
  top: 0;
  z-index: 11;
}
/*@/global/coolhole/_character.less*/
.charAbilityButton,
.charAbilityButtonContainer,
.charAbilityButtonDiv {
  float: left;
}
.charAbilityButton,
.charAbilityButtonContainer {
  width: 100%;
}
.charAbilityButtonDiv {
  padding: 4px;
  position: relative;
  width: 50%;
}
.charArmorContainer {
  float: left;
}
.characterBtn {
  float: right;
}
.charEquipContainer {
  float: left;
  width: 100%;
}
.charEquipTitle {
  text-align: center;
}
.hpBarColor,
.hpBarHUD,
.hpBarUserList {
  float: left;
  width: 100%;
}
.hpBarColor {
  height: 100%;
}
.hpBarHUD {
  height: 10px;
}
.hpBarUserList {
  border-style: solid;
}
.charItem {
  position: relative;
}
.charItem,
.charItemSlot {
  float: left;
}
.charItemHighlight,
.charItemCooldown {
  width: 100%;
  height: 100%;
}
.charItemHighlight,
.charItemCooldown,
.charItemCooldownTime {
  position: absolute;
}
.charItemHighlight {
  z-index: 100;
}
.charItemHighlight:hover {
  cursor: pointer;
}
.charItemCooldown {
  z-index: 99;
}
.charItemCooldownTime {
  top: 0;
  z-index: 11;
}
.charInnerPanel,
.charInnerPanelBlack {
  float: left;
}
.charInnerPanel,
.charInnerPanelTitle {
  width: 100%;
}
.charPanel,
.charPanelContainer,
.charPanelSection,
.charPanelTitle,
.charPanelDetails {
  float: left;
  width: 100%;
}
.charPanel,
.charPanelContainer,
.charPanelSection {
  height: 100%;
}
.charStatContainer,
.charStatLabel,
.charStatLabelHP,
.charStatValue,
.charStatDesc,
.charStatButton {
  float: left;
}
.charStatContainer,
.charStatDesc,
.charStatButton {
  width: 100%;
}
.charStatLabel,
.charStatValue {
  width: 50%;
}
.charStatValue {
  text-align: right;
}
.charStatDesc {
  overflow: auto;
}
.itemDetailActionContainer {
  width: 100%;
  float: left;
}
.charStatusBox {
  float: right;
  font-weight: bold;
  text-align: center;
}
.charWeaponContainer {
  float: left;
}
/*@/global/coolhole/_combat.less*/
.combatChat {
  width: 100%;
  float: left;
}
#combatInfo {
  width: 100%;
  overflow-y: scroll;
}
.combatMsg,
.combatMsgDamage,
.combatMsgGainHp,
.combatMsgGainPts,
.combatMsgLoseHp,
.combatMsgLosePts {
  float: left;
}
/*@/global/coolhole/_game.less*/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.gameBtn,
.gameBtnFlat {
  text-align: center;
}
.gameBtn:active,
.gameBtnFlat:active {
  cursor: pointer;
}
.gameNewButton,
.gameDeleteButton {
  float: left;
}
.gameFade {
  opacity: 0.5;
}
.gameFade:after {
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
  border-bottom: 5px solid white;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  content: "";
  height: 100px;
  width: 100px;
  left: 42%;
  top: 50%;
  position: absolute;
}
.gameFormMain,
.gameFormDetails,
.gameFormRow,
.gameFormLabel,
.gameFormInput,
.gameFormScrollPanel {
  width: 100%;
  float: left;
}
.gameFormSectionBody,
.gameFormSectionLabel,
.gameFormSection50,
.gameFormSection100 {
  float: left;
}
.gameFormSectionBody,
.gameFormSectionLabel,
.gameFormSection100 {
  width: 100%;
}
.gameFormSectionBody {
  padding-left: 10px;
  padding-right: 10px;
}
.gameFormSectionLabel {
  margin-left: 5px;
}
.gameFormSection50,
.gameFormSection100 {
  padding-bottom: 15px;
}
.gameFormSection50 {
  width: 50%;
}
.gameFormElement25,
.gameFormElement33,
.gameFormElement50,
.gameFormElement75,
.gameFormElement100 {
  float: left;
}
.gameFormElement25 {
  width: 25%;
}
.gameFormElement33 {
  width: 33%;
}
.gameFormElement50 {
  width: 50%;
}
.gameFormElement75 {
  width: 75%;
}
.gameFormElement100 {
  width: 100%;
}
.gameFormCheckbox {
  margin: 0 10px;
}
.gameFormCheckbox,
.gameFormCheckboxLabel {
  float: left;
}
.gameFormCheckboxLabel {
  width: 80%;
}
.gameFormTextbox {
  width: 70%;
}
.gameFormTextarea {
  width: 100%;
}
.gameFormSelect {
  width: 70%;
}
.gameFormHidden {
  display: none;
}
.gameFormScrollPanel {
  overflow-y: scroll;
}
.gameHoverElement,
.gameHoverLabel,
.gameHoverLabelBold,
.gameHoverValue {
  float: left;
}
.gameHoverElement {
  width: 100%;
}
.hoverBox {
  position: fixed;
  z-index: 9999;
  overflow-y: hidden;
}
.gameModal {
  position: absolute;
}
.gameModalContent,
.gameModalHeader,
.gameModalBody,
.gameModalSave,
.gameModalList,
.gameModalListWrap,
.gameModalListLabel,
.gameModalListValue,
.gameModalListTable,
.gameModalListTableBig,
.gameModalDetails,
.gameModalDetailsWrap,
.gameModalDetailsLabel {
  float: left;
  width: 100%;
}
.gameModalContent {
  background-clip: padding-box;
  outline: 0;
  position: relative;
}
.gameModalListTable,
.gameModalListTableBig {
  overflow-x: hidden;
  overflow-y: scroll;
}
.gameModalDetailsWrap {
  position: relative;
}
.gameProfileImage {
  float: right;
}
.gameTabPane {
  visibility: hidden;
  display: none;
}
.gameTabPane.active {
  float: left;
  width: 100%;
  display: inherit;
  visibility: visible;
}
/*@/global/coolhole/_hud.less*/
.hud {
  bottom: 0;
  position: fixed;
  z-index: 1005;
}
.hud,
.hudTopPart,
.hudPlaceholder,
.hudScrollButton {
  width: 100%;
}
.hudPanel,
.hudScroll {
  height: 100%;
}
.hudTopPart,
.hudPanel,
.hudPlaceholder,
.hudRespawn,
.hudScroll,
.hudScrollButton,
.hudBuff {
  float: left;
}
.hudPanel {
  z-index: -10;
}
.hudMessage {
  left: -50%;
  pointer-events: none;
  position: relative;
}
.hudMessageContainer {
  bottom: 110px;
  left: 50%;
  pointer-events: none;
  position: fixed;
  width: 60%;
  z-index: 9999;
}
.hudScrollButton {
  height: 50%;
  text-align: center;
}
.hudScrollButton:active {
  cursor: pointer;
}
.hudActionDropdown,
.hudActionDropdownRespawn {
  display: inline-block;
  position: fixed;
  vertical-align: middle;
  z-index: 1010;
}
.hudBuff {
  position: relative;
}
.hudBuffContainer {
  position: fixed;
}
/*@/global/coolhole/_item.less*/
.itemName,
.itemLoading,
.itemImage,
.itemUses {
  position: absolute;
}
.itemName,
.itemImage {
  width: 100%;
  height: 100%;
}
.itemName {
  z-index: 6;
}
.itemLoading {
  z-index: 5;
}
.itemImage {
  z-index: 10;
}
.itemUses {
  z-index: 11;
}
.itemProps,
.itemPropertyDetailsDiv,
.itemActionDetails {
  float: left;
  width: 100%;
}
.itemActionAddDiv {
  cursor: pointer;
}
table.itemActionTable {
  width: 100%;
}
table.itemActionTable tr td {
  vertical-align: middle;
}
table.itemPropertyTable {
  width: 100%;
}
/*@/global/coolhole/_job.less*/
.jobPickerPanel {
  float: left;
}
.jobPickerTextArea {
  width: 100%;
}
/*@/global/coolhole/_user.less*/
.user-dropdown {
  z-index: 9999;
  position: absolute;
}
.user-dropdown-line {
  width: 100%;
}
#userlist {
  float: left;
  list-style: none outside none;
  overflow-x: hidden;
  overflow-y: scroll;
}
.userlist_item {
  cursor: pointer;
  float: left;
  width: 100%;
}
/*@/global/coolhole/_point.less*/
#coinWrapper {
  gap: 3px;
}
#coinAmt::after {
  content: " CP";
}
#coinMsg {
  opacity: 0%;
}
.cpBounce {
  -webkit-animation: bounce 0.8s ease-in-out 0s 1 normal none;
          animation: bounce 0.8s ease-in-out 0s 1 normal none;
}
@-webkit-keyframes bounce {
  0%,
  35%,
  55%,
  75%,
  100% {
    -webkit-transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-8px);
  }
  40% {
    -webkit-transform: translateY(-4px);
  }
  60% {
    -webkit-transform: translateY(-2px);
  }
}
@keyframes bounce {
  0%,
  35%,
  55%,
  75%,
  100% {
    -webkit-transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-8px);
  }
  40% {
    -webkit-transform: translateY(-4px);
  }
  60% {
    -webkit-transform: translateY(-2px);
  }
}
.cpShake {
  -webkit-animation: shake 0.8s ease-in-out 0s 1 normal none;
          animation: shake 0.8s ease-in-out 0s 1 normal none;
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}
.cpFadeIn {
  -webkit-animation: fadeIn 1.3s ease-in-out 0s 1;
          animation: fadeIn 1.3s ease-in-out 0s 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
.cpFadeGreen {
  -webkit-animation: fade 1.3s ease-in-out 0s 1;
          animation: fade 1.3s ease-in-out 0s 1;
  color: #009900;
}
.cpFadeRed {
  -webkit-animation: fade 1.3s ease-in-out 0s 1;
          animation: fade 1.3s ease-in-out 0s 1;
  color: #E03E40;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0%;
  }
  20% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
@keyframes fade {
  0% {
    opacity: 0%;
  }
  20% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
.cp-option-form {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.cp-option-form:hover {
  background-color: hsla(0, 0%, 100%, 0.05);
}
.cp-option-subheader {
  text-align: center;
  font-weight: bold;
}
.cp-option-form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.cp-option-form-group {
  gap: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cp-option-justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cp-option-label {
  -webkit-box-flex: 0.3;
      -ms-flex: 0.3;
          flex: 0.3;
}
.cp-option-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.cp-option-timeinput {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.cpActionRow {
  display: grid;
  padding: 0.5rem;
  gap: 1.5rem;
  grid-template-columns: 2fr [desc-col] 1fr [cost-col];
}
.cpActionDescription {
  -webkit-box-flex: 0.7;
      -ms-flex: 0.7;
          flex: 0.7;
}
.cpActionCost {
  -webkit-box-flex: 0.2;
      -ms-flex: 0.2;
          flex: 0.2;
  font-size: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}
.cpActionCost.cp-earnings::before {
  content: "+";
  color: green;
}
.cpActionCost.cp-losses::before {
  content: "-";
  color: red;
}
.cpActionCost.cp-expenditures::before {
  content: "-";
  color: #f55f5f;
}
.cpActionCost.cp-statuses::before {
  content: "<";
  color: #f9f95c;
}
.cpActionRow:hover {
  background-color: hsla(0, 0%, 100%, 0.05);
}
.cpActionDisabled {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.cp-1-percentile {
  -webkit-animation: subtle-glow 3s infinite;
          animation: subtle-glow 3s infinite;
  color: hsl(163, 74%, 49%);
}
.cp-100-percentile {
  color: hsla(0, 0%, 100%, 0.3);
}
@-webkit-keyframes subtle-glow {
  0% {
    opacity: 0.8;
    text-shadow: none;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px hsl(43, 74%, 49%);
  }
  100% {
    opacity: 0.8;
    text-shadow: none;
  }
}
@keyframes subtle-glow {
  0% {
    opacity: 0.8;
    text-shadow: none;
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 5px hsl(43, 74%, 49%);
  }
  100% {
    opacity: 0.8;
    text-shadow: none;
  }
}
#cp-greatreset {
  padding: 12px 24px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
#cp-greatreset:before {
  content: 'There is no going back';
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
#cp-greatreset:hover:before {
  opacity: 1;
}
#cp-greatreset:hover span {
  opacity: 0;
}
#cp-greatreset span {
  display: inline-block;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
